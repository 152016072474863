export default{
    icon: "",
    id: 10000000,
    level: 1,
    menuStatus: 1,
    menuType: 2,
    menus: [
        {
            icon: "haolv-iconbook",
            id: 1000000001,
            level: 2,
            menuStatus: 1,
            menuType: 2,
            menus: [
                {
                    icon: "",
                    id: 100000000101,
                    level: 3,
                    menuStatus: 1,
                    menuType: 2,
                    menus: null,
                    metaName: "information",
                    name: "我的信息",
                    orderNum: 1,
                    parentId: 1000000001,
                    perms: "information211",
                    redirect: null,
                    roleId: null,
                    routeName: "admin-my-information",
                    type: null,
                    url: "/user/signUser;/sys/sms/vcodeVerify;/user/updatePhone;/user/updateUser;/sys/sms/vcodeSend",
                }
            ],
            metaName: "information",
            name: "我的信息",
            orderNum: 1,
            parentId: 10000000,
            perms: "21",
            redirect: null,
            roleId: null,
            routeName: "",
            type: null,
            url: "",
        },
        {
            icon: "el-icon-edit-outline",
            id: 1000000002,
            level: 2,
            menuStatus: 1,
            menuType: 2,
            menus: [
                {
                    icon: "",
                    id: 100000000201,
                    level: 3,
                    menuStatus: 1,
                    menuType: 2,
                    menus: null,
                    metaName: "password",
                    name: "修改密码",
                    orderNum: 1,
                    parentId: 1000000002,
                    perms: "password221",
                    redirect: null,
                    roleId: null,
                    routeName: "admin-change-password",
                    type: null,
                    url: "/user/checkPassword;/user/updatePassword"
                }
            ],
            metaName: "password",
            name: "修改密码",
            orderNum: 2,
            parentId: 10000000,
            perms: "22",
            redirect: null,
            roleId: null,
            routeName: "",
            type: null,
            url: "",
        },
        {
            icon: "haolv-iconbusiness-travel",
            id: 1000000003,
            level: 2,
            menuStatus: 1,
            menuType: 2,
            menus: [
                {
                    icon: "",
                    id: 100000000301,
                    level: 3,
                    menuStatus: 1,
                    menuType: 2,
                    menus: null,
                    metaName: "business-travel-standard",
                    name: "我的差标",
                    orderNum: 1,
                    parentId: 1000000003,
                    perms: "business-travel-standard231",
                    redirect: null,
                    roleId: null,
                    routeName: "admin-business-travel-standard",
                    type: null,
                    url: "/web/policyConfig_myTravelPolicy"
                }
            ],
            metaName: "business-travel-standard",
            name: "我的差标",
            orderNum: 3,
            parentId: 10000000,
            perms: "23",
            redirect: null,
            roleId: null,
            routeName: "",
            type: null,
            url: ""
        },
        {
            icon: "haolv-iconworker",
            id: 1000000004,
            level: 2,
            menuStatus: 1,
            menuType: 2,
            menus: [
                {
                    icon: "",
                    id: 100000000401,
                    level: 3,
                    menuStatus: 1,
                    menuType: 2,
                    menus: null,
                    metaName: "traveller",
                    name: "常用旅客",
                    orderNum: 1,
                    parentId: 1000000004,
                    perms: "traveller241",
                    redirect: null,
                    roleId: null,
                    routeName: "admin-often-traveller",
                    type: null,
                    url: "/frequent/contacts/getList;/frequent/contacts/del;/frequent/contacts/addOrUpdate"
                }
            ],
            metaName: "traveller",
            name: "常用旅客",
            orderNum: 4,
            parentId: 10000000,
            perms: "24",
            redirect: null,
            roleId: null,
            routeName: "",
            type: null,
            url: "",
        }
    ],
    metaName: "personalCenter",
    name: "个人中心",
    orderNum: 10000,
    parentId: 0,
    perms: "travelOrder2",
    redirect: null,
    roleId: null,
    routeName: "",
    type: null,
    url: "/apply/approveList;/apply/myApplyList;/department/getDepartmentUsers;/evection/addOrUpdate;/web/externalCustomer/getExternalCustomerAll;/notice/list"

}

