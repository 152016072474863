import Q from "q";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import consumer_user_logout from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_user_logout";
import * as dd from "dingtalk-jsapi";

import DownlodApp from "@/component/download-app/index.vue";

export default {
  data() {
    return {
      userInfo: {},
      fullscreenLoading:false,
    };
  },
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DownlodApp,
  },
  created() {},
  mounted() {
    get_user_info().then((res) => {
      this.userInfo = res.datas;
    });
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {


    to_personal_center () {
      this.$router.push({
        name: 'admin-my-information'
      })
    },

    jumpNext(val) {
      let routeName = "";
      if (val.routeName) {
        routeName = val.routeName;
      } else if (val.children[0].routeName) {
        routeName = val.children[0].routeName;
      } else {
        routeName = val.children[0].children[0].routeName;
      }
      this.$router.push({
        name: routeName,
      });
    },

    to_home() {
      if (this.$route.name !== "admin-travel-book-home") {
        this.$router.push({ name: "admin-travel-book-home" });
      }
    },

    __btn_logout_click() {
      const __this = this;
      this.fullscreenLoading = true;
      Q.when()
        .then(function() {
          return consumer_user_logout();
        })
        .then(() => {
          if (dd.env.platform !== "notInDingTalk") {
            dd.getStorage({
              key: 'corpId',
              success: (res) => {
                __this.$router.replace({
                  name: `dd-login`,
                  query: {
                    corpId: res,
                  }
                });
              },
              fail: () => {},
              complete: () => {},
            });
            return;
          }
          __this.$router.replace({
            name: `login`,
          });
        })
        .catch(function(ex) {
          if (dd.env.platform !== "notInDingTalk") {
            dd.getStorage({
              key: 'corpId',
              success: (res) => {
                __this.$router.replace({
                  name: `dd-login`,
                  query: {
                    corpId: res,
                  }
                });
              },
              fail: () => {},
              complete: () => {},
            });
            return;
          }
          __this.$router.replace({
            name: `login`,
          });
          console.error(ex);
          console.trace();
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        });
    },
  },
};
